import { Injectable } from '@angular/core';
import { UtilsService } from '../utils/utils.service';
import { AlertController, ToastController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import Swal from 'sweetalert2';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	private toasts: Array<HTMLIonToastElement> = [];
	private loading: HTMLIonLoadingElement;

	constructor(
		private utilsSrv: UtilsService,
		private alertCtrl: AlertController,
		private toastCtrl: ToastController,
		private loadingController: LoadingController
	) {}

	async showLoading(loadingMsg?: string) {
		if (!this.loading) {
			this.loading = await this.loadingController.create({
				message: loadingMsg,
				animated: true,
				keyboardClose: false,
				backdropDismiss: false,
			});
			await this.loading.present();
		} else {
			this.loading.message = loadingMsg;
		}
	}

	async dismissLoading() {
		if (this.loading) {
			await this.loading.dismiss();
			this.loading = undefined;
		}
	}

	/**
	 * Notifies user with success message
	 */
	success(msg: string, title?: string, toast = true, timer = 2000) {
		if (this.utilsSrv.isPhone && toast) {
			this.showToast(msg, title, 'top', timer, 'dp-success-toast', 'success');
		} else {
			this.showSweetAlert({
				msg: msg,
				title: title,
				icon: 'success',
				toast: toast,
				timer: timer,
			});
		}
	}

	/**
	 * Notifies user of given error
	 */
	error(msg: string, title?: string, code?: string | number, toast = true, timer = 4000) {
		if (code) {
			msg += '<hr>Fehlercode: ' + code;
		}

		if (this.utilsSrv.isPhone && toast) {
			this.showToast(msg, title, 'top', timer, 'dp-error-toast', 'error');
		} else {
			this.showSweetAlert({
				msg: msg,
				title: title,
				icon: 'error',
				toast: toast,
				timer: timer,
			});
		}
	}

	/**
	 * Notifies user with info message
	 */
	info(msg: string, title?: string, toast = true, timer = 5000) {
		if (this.utilsSrv.isPhone && toast) {
			this.showToast(msg, title, 'top', timer, 'dp-info-toast', 'info');
		} else {
			this.showSweetAlert({
				msg: msg,
				title: title,
				icon: 'info',
				toast: toast,
				timer: timer,
			});
		}
	}

	/**
	 * Shows confirmation dialog. Returns promise which resolves when the user confirms the action
	 */
	confirm(msg: string, title?: string, confirmText = 'OK', cancelText = 'Abbrechen', destructive = false) {
		return new Promise((resolve, reject) => {
			if (this.utilsSrv.isNative) {
				this.alertCtrl
					.create({
						message: msg,
						header: title,
						cssClass: 'dp-confirm-dialog',
						buttons: [
							{
								text: confirmText,
								role: destructive ? 'destructive' : 'confirm',
								handler: () => {
									console.log('Confirm dialog accepted');
									resolve(undefined);
								},
							},
							{
								text: cancelText,
								role: 'cancel',
								handler: () => {
									console.log('Confirm dialog canceled');
									reject();
								},
							},
						],
					})
					.then((alert) => {
						alert.present();
					});
			} else {
				Swal.fire({
					title: title,
					html: msg,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: confirmText,
					cancelButtonText: cancelText,
					confirmButtonColor: destructive ? '#eb445a' : '#2092c9',
					cancelButtonColor: '#ffc409',
					heightAuto: false, // for ionic compatibility
				})
					.then((res) => {
						if (res.isConfirmed) {
							resolve(undefined);
						} else {
							reject();
						}
					})
					.catch(() => {
						reject();
					});
			}
		});
	}

	/**
	 * Shows confirmation dialog. Returns promise which resolves when the user confirms the action
	 */
	successConfirm(msg: string, title?: string, confirmText = 'OK', destructive = false) {
		return new Promise((resolve, reject) => {
			if (this.utilsSrv.isNative) {
				this.alertCtrl
					.create({
						message: msg,
						header: title,
						cssClass: 'dp-confirm-dialog',
						buttons: [
							{
								text: confirmText,
								role: destructive ? 'destructive' : 'confirm',
								handler: () => {
									console.log('Confirm dialog accepted');
									resolve(undefined);
								},
							},
						],
					})
					.then((alert) => {
						alert.present();
					});
			} else {
				Swal.fire({
					title: title,
					html: msg,
					icon: 'success',
					showCancelButton: false,
					confirmButtonText: confirmText,
					confirmButtonColor: destructive ? '#eb445a' : '#2092c9',
					heightAuto: false, // for ionic compatibility
				})
					.then((res) => {
						if (res.isConfirmed) {
							resolve(undefined);
						} else {
							reject();
						}
					})
					.catch(() => {
						reject();
					});
			}
		});
	}

	/**
	 * Shows prompt dialog with a single text input. Returns promise which resolves with the input value or undefined if empty. Cancel action rejects the promise
	 */
	prompt(msg: string, title?: string, prefilledValue?: string, placeholder?: string) {
		return new Promise((resolve, reject) => {
			this.alertCtrl
				.create({
					message: msg,
					header: title,
					cssClass: 'dp-confirm-dialog',
					inputs: [
						{
							name: 'text_input',
							type: 'text',
							value: prefilledValue,
							placeholder: placeholder,
						},
					],
					buttons: [
						{
							text: 'OK',
							handler: (data) => {
								console.log('Prompt dialog accepted', data);
								resolve(data ? data.text_input : undefined);
							},
						},
						{
							text: 'Abbrechen',
							role: 'cancel',
							handler: () => {
								console.log('Prompt dialog canceled');
								reject();
							},
						},
					],
				})
				.then(async (alert) => {
					await alert.present();

					// as we don't have a native API for triggering focus and keyups we do it ourselves
					let items = alert.getElementsByTagName('input');
					if (items.length > 0) {
						items[0].addEventListener('keyup', (event) => {
							// Number 13 is the "Enter" key on the keyboard
							if (event.key == 'Enter' || event.keyCode === 13) {
								// See above button handler in alert's buttons
								// we do the same here for correct further handling in app
								alert.dismiss({ text_input: items[0].value || undefined });
								resolve(items[0].value || undefined);
							}
						});
						items[0].focus();
					}
				});
		});
	}

	/**
	 * Shows a sweet alert with given configuration
	 */
	showSweetAlert(options: { msg: string; title: string; icon: 'success' | 'error' | 'warning' | 'info' | 'question'; toast: boolean; timer: number }) {
		return Swal.fire({
			title: options.title,
			html: options.msg,
			icon: options.icon,
			toast: options.toast,
			timer: options.timer,
			timerProgressBar: true,
			showConfirmButton: false,
			position: this.utilsSrv.mobileViewActive ? 'top' : 'top-right',
			heightAuto: false, // for ionic compatibility
		});
	}

	async showToast(
		message: string,
		header?: string,
		position: 'top' | 'bottom' | 'middle' = 'bottom',
		duration = 3000,
		id?: String,
		type: 'blank' | 'info' | 'error' | 'warn' | 'success' = 'blank'
	) {
		let toastOpts = {
			header: header,
			message: message,
			duration: duration,
			position: position,
			cssClass: 'dp-toast',
			animated: true,
			translucent: false,
			color: 'light',
			buttons: [
				{
					side: 'end',
					text: 'OK',
					role: 'cancel',
				},
			],
		} as any;

		// prepare extra data
		if (id) {
			toastOpts['id'] = id;
		}
		if (type != 'blank') {
			let iconBtn = 'alert-circle-outline';
			switch (type) {
				case 'error':
					toastOpts.color = 'danger';
					iconBtn = 'close-circle-outline';
					break;
				case 'warn':
					toastOpts.color = 'warning';
					iconBtn = 'warning-outline';
					break;
				case 'info':
					toastOpts.color = 'light';
					iconBtn = 'alert-circle-outline';
					break;
				case 'success':
					toastOpts.color = 'success';
					iconBtn = 'checkmark-circle-outline';
					break;
			}

			toastOpts.buttons.push({
				side: 'start',
				icon: iconBtn,
			});
		}

		let toast = await this.toastCtrl.create(toastOpts);

		if (id) {
			// when using an id only show them once
			let toastIndex = this.toasts.findIndex((t) => t.id == id);
			if (toastIndex != -1) {
				await this.toasts[toastIndex].dismiss();
			}
			this.toasts.push(toast);
			await toast.present();

			await toast.onDidDismiss();
			this.toasts = this.toasts.filter((t) => t.id != id);
			this.toasts = this.toasts.filter((t) => t.id != id);
		} else {
			await toast.present();
		}
	}

	async dismissToastsById(ids: Array<string>) {
		for (let id of ids) {
			let toast = this.toasts.find((t) => t.id == id);
			if (toast) {
				setTimeout((_) => {
					toast.dismiss();
				}, 200);
			}
		}
	}
}
