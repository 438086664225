import { UserType } from './user-type.enum';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthInfo } from './auth-info.interface';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import * as AWS from 'aws-sdk';
import { environment } from '../../environments/environment';
import { LoggerService } from '../core/logger/logger.service';
import { NotificationService } from '../core/notification/notification.service';
import { Router } from '@angular/router';
import { AuthenticationResultType } from 'aws-sdk/clients/cognitoidentityserviceprovider';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	// login status handling
	public isLoggedIn = false;
	public loginStatusChanged$ = new BehaviorSubject<boolean | string>(this.isLoggedIn);
	public credentialsChange = new EventEmitter();

	public lastUsedAccessToken: string;
	public lastUsedIdToken: string;

	// auth info
	public authInfo: AuthInfo;
	public userType: UserType;
	public isProducer = false;
	public isTransporter = false;

	// helper variables
	private loggerName = '[AUTH SERVICE] '; // the name for the log messages
	private cognitoISP: CognitoIdentityServiceProvider;
	private currentCognitoUserName;
	private currentCognitoUserSession;
	private lastUsedRefreshToken: string;

	constructor(private router: Router, private loggerSrv: LoggerService, private notificationSrv: NotificationService) {
		this.cognitoISP = new AWS.CognitoIdentityServiceProvider({
			apiVersion: '2016-04-18',
			region: environment.AWS_REGION,
			credentials: {
				accessKeyId: 'anything',
				secretAccessKey: 'anything',
			},
		});
	}

	/**
	 * Logs the user in with given username and password
	 * @param username
	 * @param password
	 */
	startLogin(username: string, password: string) {
		this.currentCognitoUserName = username;

		console.log({
			AuthFlow: 'USER_PASSWORD_AUTH',
			AuthParameters: {
				USERNAME: username,
				PASSWORD: password,
			},
			ClientId: environment.AWS_COGNITO_APP_CLIENT_ID,
		});

		this.cognitoISP.initiateAuth(
			{
				AuthFlow: 'USER_PASSWORD_AUTH',
				AuthParameters: {
					USERNAME: username,
					PASSWORD: password,
				},
				ClientId: environment.AWS_COGNITO_APP_CLIENT_ID,
			},
			(err, data) => {
				if (err) {
					this.loggerSrv.error(this.loggerName + 'login failed', err);
					if (err.statusCode == 400) {
						this.notificationSrv.error('Benutzername oder Passwort falsch', 'Login fehlgeschlagen');
					} else {
						this.notificationSrv.error('Bitte versuche es erneut', 'Login fehlgeschlagen');
					}
					this.abortLoginProcess();
				} else {
					if (data.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
						// handle new password required challenge
						this.currentCognitoUserSession = data.Session;
						this.loggerSrv.log(this.loggerName + 'login new password required');
						this.isLoggedIn = false;
						this.loginStatusChanged$.next('passwordchange');
					} else {
						// login successful, handle authenticationResult
						this.loggerSrv.log(this.loggerName + 'login successful', data);
						this.handleLogin(data.AuthenticationResult);
					}
				}
			}
		);
	}

	/**
	 * Trys to refresh the session by checking and renewing the existing tokens
	 */
	refreshSession() {
		return new Promise((resolve, reject) => {
			const tokens = this.getTokens();

			this.loggerSrv.log(this.loggerName + 'refresh session: found local tokens', tokens);
			this.currentCognitoUserName = tokens.userName;
			this.cognitoISP.initiateAuth(
				{
					AuthFlow: 'REFRESH_TOKEN_AUTH',
					AuthParameters: {
						REFRESH_TOKEN: tokens.refreshToken,
					},
					ClientId: environment.AWS_COGNITO_APP_CLIENT_ID,
				},
				(err, data) => {
					if (err) {
						this.loggerSrv.error(this.loggerName + 'could not refresh session', err);
						this.abortLoginProcess();
						reject();
					} else {
						this.loggerSrv.log(this.loggerName + 'refreshed session successfuly', data);
						this.saveTokens(data.AuthenticationResult); // save tokens
						this.credentialsChange.emit();
						this.getUserData()
							.then((userData) => {
								this.authInfo = userData;
								this.loggerSrv.log(this.loggerName + 'got user data from local storage', userData);
								this.concludeLoginProcess()
									.then(() => {
										resolve(undefined);
									})
									.catch(() => {
										this.abortLoginProcess();
										reject();
									});
							})
							.catch((userDataErr) => {
								this.loggerSrv.error(this.loggerName + 'could not update user data', userDataErr);
								this.abortLoginProcess();
								reject();
							});
					}
				}
			);
		});
	}

	/**
	 * Handles the login process with given authenticationResult
	 * Checks the userRole to redirect
	 * @param authenticationResult
	 */
	private handleLogin(authenticationResult: AuthenticationResultType) {
		this.saveTokens(authenticationResult);
		this.updateUserData(authenticationResult.AccessToken)
			.then((userData) => {
				let userRole;
				if (userData.UserAttributes[3].Value) {
					userRole = userData.UserAttributes[3].Value;
				}
				this.authInfo = userData;
				this.loggerSrv.log(this.loggerName + 'updated user data', userData);
				this.concludeLoginProcess()
					.then(() => {
						this.userType = userRole as UserType;
						if (this.userType === UserType.ADMIN) {
							this.router.navigate(['/']);
							this.notificationSrv.success('', 'Login erfolgreich');
						}
						// Redirect other users to camera
						else {
							this.router.navigate(['/']);
							this.notificationSrv.success('', 'Login erfolgreich');
						}
					})
					.catch(() => {
						this.abortLoginProcess();
					});
			})
			.catch((dataErr) => {
				this.loggerSrv.error(this.loggerName + 'could not update user data', dataErr);
				this.abortLoginProcess();
			});
	}

	/**
	 * Concludes the login process by preparing the app for use
	 */
	private async concludeLoginProcess() {
		// update credentials for aws sdk
		let logins = {};
		logins['cognito-idp.eu-central-1.amazonaws.com/' + environment.AWS_COGNITO_APP_USER_POOL_ID] = this.lastUsedIdToken;

		AWS.config.update({
			region: 'eu-central-1',
			credentials: new AWS.CognitoIdentityCredentials({
				IdentityPoolId: environment.AWS_COGNITO_APP_IDENTITY_POOL_ID,
				Logins: logins,
			}),
		});

		// update login status
		this.isLoggedIn = true;
		this.loginStatusChanged$.next(this.isLoggedIn);
		this.loggerSrv.log(this.loggerName + 'login process concluded');
	}

	/**
	 * Something wrent wrong during login, aborts the login by adjusting various variables
	 */
	private abortLoginProcess() {
		AWS.config.credentials = null; // remove credentials for aws services

		this.isLoggedIn = false;
		this.loginStatusChanged$.next(this.isLoggedIn);
		this.loggerSrv.log(this.loggerName + 'login process aborted');
	}

	/**
	 * Changes password for user when required on first login
	 */
	completeNewPasswordChallenge(newPassword: string) {
		this.cognitoISP.respondToAuthChallenge(
			{
				ClientId: environment.AWS_COGNITO_APP_CLIENT_ID,
				ChallengeName: 'NEW_PASSWORD_REQUIRED',
				ChallengeResponses: {
					USERNAME: this.currentCognitoUserName,
					NEW_PASSWORD: newPassword,
				},
				Session: this.currentCognitoUserSession,
			},
			(err, data) => {
				if (err) {
					switch (err.message) {
						case 'Password does not conform to policy: Password not long enough':
						case 'Password does not conform to policy: Password must have numeric characters':
						case 'Password does not conform to policy: Password must have lowercase characters':
						case 'Password does not conform to policy: Password must have uppercase characters':
							this.notificationSrv.error('Das eingegebene Passwort entspricht nicht den Passwortrichtlinien', 'Login fehlgeschlagen');
							break;
						default:
							break;
					}
					this.loggerSrv.error(this.loggerName + 'password challange error:', err);
				} else {
					this.loggerSrv.log(this.loggerName + 'new password challenge successful', data);
					this.handleLogin(data.AuthenticationResult);
				}
			}
		);
	}

	/**
	 * Deletes local data and logs the user out
	 */
	logoutUser() {
		Promise.all([
			localStorage.clear(), // clear local storage
		])
			.then((clearRes) => {
				this.loggerSrv.log(this.loggerName + 'cleared local storage', clearRes);
				this.isLoggedIn = false;
				this.loginStatusChanged$.next(false);
				this.router.navigate(['/auth']);
				window.location.reload();
			})
			.catch((clearErr) => {
				this.loggerSrv.error(this.loggerName + 'logout: could not clear local storage', clearErr);
			});
	}

	/**
	 * Saves given tokens and current user name to local storage for given authResult
	 * @param authResult
	 */
	private saveTokens(authResult: AuthenticationResultType) {
		let tokens = [];
		if (authResult.AccessToken) {
			this.lastUsedAccessToken = authResult.AccessToken;
			localStorage.setItem('arnold_auth_accesstoken', authResult.AccessToken);
			tokens.push(authResult.AccessToken);
		}
		if (authResult.IdToken) {
			this.lastUsedIdToken = authResult.IdToken;
			localStorage.setItem('arnold_auth_idtoken', authResult.IdToken);
			tokens.push(authResult.IdToken);
		}
		if (authResult.RefreshToken) {
			this.lastUsedRefreshToken = authResult.RefreshToken;
			localStorage.setItem('arnold_auth_refreshtoken', authResult.RefreshToken);
			tokens.push(authResult.RefreshToken);
		}

		localStorage.setItem('arnold_auth_username', this.currentCognitoUserName);

		this.loggerSrv.log(this.loggerName + 'tokens saved', tokens);
	}

	/**
	 * Gets tokens from local storage. Returns a promise with all tokens and username
	 */
	getTokens() {
		const accessToken = localStorage.getItem('arnold_auth_accesstoken');
		const idToken = localStorage.getItem('arnold_auth_idtoken');
		const refreshToken = localStorage.getItem('arnold_auth_refreshtoken');
		const userName = localStorage.getItem('arnold_auth_username');

		return {
			accessToken: accessToken,
			idToken: idToken,
			refreshToken: refreshToken,
			userName: userName,
		};
	}

	/**
	 * Gets user data from server and saves the user data (and company data) of logged in user to local storage
	 * Returns a promise
	 */
	updateUserData(accesToken: string) {
		return new Promise<AuthInfo>((resolve, reject) => {
			this.cognitoISP.getUser({ AccessToken: accesToken }, (error, userData: AuthInfo) => {
				if (error) {
					reject({
						msg: 'could not get correct userData',
						userData: userData,
					});
				}

				// save data to local storage
				localStorage.setItem('arnold_auth_userdata', JSON.stringify(userData));
				resolve(userData);
			});
		});
	}

	/**
	 * Returns the user data (and company data) which is saved in local storage
	 * Returns a promise
	 */
	getUserData() {
		return new Promise<AuthInfo>((resolve, reject) => {
			const userData: AuthInfo = JSON.parse(localStorage.getItem('arnold_auth_userdata'));

			// check if user data is null => error local user data ist corrupt
			if (userData == null) {
				reject({
					msg: 'local userdata corrupt',
					userData: userData,
				});
			}

			resolve(userData);
		});
	}
}
