export const environment = {
	production: true,

	AWS_REGION: 'eu-central-1',

	AWS_COGNITO_APP_USER_POOL_ID: 'eu-central-1_6t248gX1V',
	AWS_COGNITO_APP_CLIENT_ID: '15der8l5l88gpu85hp8tv8ovdk',
	AWS_COGNITO_APP_IDENTITY_POOL_ID: 'eu-central-1:ef3bc14d-2ad3-4d8a-a817-99c21d82e66f',

	AWS_API_URL: 'https://j1p05igmwk.execute-api.eu-central-1.amazonaws.com/prod/',
	AWS_S3_BUCKET_NAME: 'arnolddata',
	AWS_S3_CUSTOMER_DATA_BASE_PATH: 'part-data/',
	AWS_DYNAMODB_TABLE: 'arnold_data',
};
