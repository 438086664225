import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TooltipDirective} from './tooltip/tooltip.directive';
import {HeaderComponent} from './header/header.component';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DialogComponent} from './dialog/dialog.component';
import {LoadingIndicatorComponent} from './loading-indicator/loading-indicator.component';
import {MobileMenuComponent} from './mobile-menu/mobile-menu.component';
import {CodeScannerComponent} from './code-scanner/code-scanner.component';
import {ZXingScannerModule} from '@zxing/ngx-scanner';

@NgModule({
  declarations: [
    HeaderComponent,
    DialogComponent,
    LoadingIndicatorComponent,
    TooltipDirective,
    MobileMenuComponent,
    CodeScannerComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FontAwesomeModule,
    ZXingScannerModule
  ],
  exports: [
    HeaderComponent,
    TooltipDirective,
    DialogComponent,
    LoadingIndicatorComponent,
    MobileMenuComponent,
    CodeScannerComponent
  ]
})
export class CoreModule {
}
