<ion-menu
	side="end"
	menuId="mobile-main-menu"
	contentId="main"
	type="push"
	class="mobile-menu"
	swipeGesture="false"
	(ionDidClose)="handleClose()"
	(ionDidOpen)="handleOpen()"
	#mobileAppMenu
>
	<ion-header class="mobile-menu-header">
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-item
					lines="none"
					class="user-info"
					(click)="onToggleMobileMenu()"
				>
					<ion-avatar slot="start">
						<ion-icon
							name="person-circle-outline"
							size="large"
						></ion-icon>
					</ion-avatar>
					<ion-label>
						<h3>
							{{ currentUserFirstName }} {{ currentUserLastName }}
						</h3>
						<p>{{ this.authSrv.authInfo?.Username }}</p>
					</ion-label>
				</ion-item>
			</ion-buttons>
			<ion-buttons slot="end">
				<ion-button
					class="menu-header-button"
					(click)="onToggleMobileMenu()"
				>
					<ion-icon name="close-outline" slot="icon-only"></ion-icon>
				</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content>
		<ion-list (click)="onToggleMobileMenu()">
			<ion-item
				button
				lines="none"
				detail="true"
				[routerLink]="['/']"
				[routerDirection]="'root'"
			>
				<fa-icon slot="start" icon="tasks"></fa-icon>
				<ion-label>Übersicht</ion-label>
			</ion-item>

			<ng-container *ngIf="currentUserRole == 'admin'">
				<ion-item
					button
					[routerLink]="['/user-management']"
					routerDirection="forward"
					lines="none"
					detail="true"
				>
					<fa-icon slot="start" icon="users"></fa-icon>
					<ion-label>Benutzerverwaltung</ion-label>
				</ion-item>
			</ng-container>

			<ion-item-divider></ion-item-divider>

			<ion-item button lines="none" (click)="onLogout()">
				<fa-icon slot="start" icon="sign-out-alt"></fa-icon>
				<ion-label> Abmelden </ion-label>
			</ion-item>
		</ion-list>
	</ion-content>
	<ion-footer class="mobile-menu-footer">
		<ion-toolbar class="version-toolbar">
			<ion-label slot="start">Version</ion-label>
			<ion-label slot="end" class="version-tag">
				<span
					>{{ versionInfo }}
					<ng-container *ngIf="appBuild"
						>({{ appBuild }})
					</ng-container></span
				>
				<ion-badge
					class="beta-tag"
					mode="ios"
					color="danger"
					*ngIf="isBetaVersion"
					>Beta</ion-badge
				></ion-label
			>
		</ion-toolbar>
	</ion-footer>
</ion-menu>
