import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Network, ConnectionStatus } from '@capacitor/network';
import { debounceTime } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { LoggerService } from '../logger/logger.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class UtilsService {
	isNative = false;
	isPhone = false;
	isTablet = false;

	mobileViewActive = false; // for CSS specific breakpoints, no real feature dependency
	fullViewActive = false;
	mobileMainMenuDidClose = new BehaviorSubject<void>(undefined);
	mobileMainMenuDidOpen = new BehaviorSubject<void>(undefined);

	private _mobileBreakpoint = 950;
	private _fullSizeBreakpoint = 1330; // at which width the view should be treated to "properly fit" all data

	private _isOnline = false;
	get isOnline() {
		return this._isOnline;
	}

	set isOnline(val: boolean) {
		if (this._isOnline !== val) {
			this.onlineChanged.next(val);
		}

		this._isOnline = val;
	}

	onlineChanged = new BehaviorSubject<boolean>(this._isOnline); // emitted when connected or disconnected

	constructor(private platform: Platform, private logger: LoggerService) {
		// Detect our running environment on start...
		this.platform.ready().then((_) => {
			this.isNative = this.platform.is('hybrid') || this.platform.is('electron');
			this.detectPlatformEnvironment();
		});
		// ... and changing screen size
		this.platform.resize.pipe(debounceTime(200)).subscribe((_) => {
			this.detectPlatformEnvironment();
		});

		// present our online state based on the navigator
		this.isOnline = navigator.onLine;

		Network.getStatus()
			.then((status) => {
				this.logger.log('[UTILS] initial network status', status);
				this.isOnline = status.connected;
			})
			.catch((statusErr) => {
				this.logger.error('[UTILS] failed getting initial network status', statusErr);
				// this.sentryCaptureException(statusErr);
			});

		Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
			this.logger.log('[UTILS] network change', status);
			this.isOnline = status.connected;
		});
	}

	/**
	 * Updates the app's environment variables like being native or small mobile screen etc.
	 */
	private detectPlatformEnvironment() {
		const smallest = Math.min(this.platform.width(), this.platform.height());
		const largest = Math.max(this.platform.width(), this.platform.height());

		// FIXME: fix the edge case of the Huawei phablet keyboard
		// this.isPhone = this.platform.is("mobile") && smallest <= 460 && (this.platform.is("android") || this.platform.is("ios"));
		this.isPhone = this.platform.is('mobile') && smallest <= 860 && (this.platform.is('android') || this.platform.is('ios'));
		this.isTablet =
			this.platform.is('ipad') ||
			(this.platform.is('mobile') &&
				smallest > 460 &&
				smallest < 820 &&
				largest > 780 &&
				largest < 1400 &&
				(this.platform.is('android') || this.platform.is('ios')));

		this.mobileViewActive = this.platform.width() <= this._mobileBreakpoint;
		this.fullViewActive = this.platform.width() >= this._fullSizeBreakpoint;

		this.applyScreenLock();
	}

	private applyScreenLock() {
		// Smartphones should not be able to rotate the screen as the UI is optimized for portrait
		if (this.isPhone) {
			window.screen.orientation.lock('portrait').catch((err) => {
				this.logger.warn('[UTILS] Could not lock oritentation', err);
			});
		}
	}

	/**
	 * Takes given array and returns a formArray
	 * @param array The given array
	 */
	arrayToFormArray(array: Array<any>) {
		const formArray = new FormArray([]);

		for (const item of array) {
			if (typeof item === 'object') {
				formArray.push(this.objectToFormGroup(item));
			} else {
				formArray.push(new FormControl(item));
			}
		}
		return formArray;
	}

	/**
	 * Takes given object and returns a formGroup
	 * @param object The given object
	 * @param requiredControls Array of property names that shall be required
	 */
	objectToFormGroup(object: object, requiredControls?: Array<string>) {
		const formGroup = new FormGroup({});
		for (const propertyName of Object.keys(object)) {
			const propertyValue = object[propertyName];
			const propertyRequired = requiredControls != null && requiredControls.includes(propertyName);
			if (propertyValue == null) {
				if (propertyRequired) {
					formGroup.addControl(propertyName, new FormControl(object[propertyName], Validators.required));
				} else {
					formGroup.addControl(propertyName, new FormControl(object[propertyName]));
				}
			} else if (Array.isArray(propertyValue)) {
				formGroup.addControl(propertyName, this.arrayToFormArray(propertyValue));
			} else if (typeof propertyValue === 'object') {
				formGroup.addControl(propertyName, this.objectToFormGroup(propertyValue, requiredControls));
			} else {
				if (propertyRequired) {
					formGroup.addControl(propertyName, new FormControl(object[propertyName], Validators.required));
				} else {
					formGroup.addControl(propertyName, new FormControl(object[propertyName]));
				}
			}
		}
		return formGroup;
	}

	/**
	 * This method generates a random password
	 * @param upLetters the amount of uppercase letters which are in the password string
	 * @param lowLetters the amount of lowercase letters which are in the password string
	 * @param numbers the amount of numbers which are in the password string
	 * @param either the amount of other letters which are in the password string
	 */
	randomPassword(upLetters, lowLetters, numbers, either): string {
		const chars = [
			'ABCDEFGHJKLMNPQRSTUVWXYZ', // uppercase letters
			'abcdefghijkmnopqrstuvwxyz', // lowercase letters
			'123456789', // numbers
			'@$!%*#?&', // either
		];

		return [upLetters, lowLetters, numbers, either]
			.map((len, i) => {
				return Array(len)
					.fill(chars[i])
					.map((x) => {
						return x[Math.floor(Math.random() * x.length)];
					})
					.join('');
			})
			.concat()
			.join('')
			.split('')
			.sort(() => {
				return 0.5 - Math.random();
			})
			.join('');
	}

	/**
	 * Helper function for providing a way to react to the mobile main menu's opening and closing events
	 * @param state "open" or "close" but currently only triggers close events
	 */
	triggerMobileMainMenuStatus(state: 'open' | 'close' = 'close') {
		if (state == 'open') {
			this.mobileMainMenuDidOpen.next();
		} else {
			this.mobileMainMenuDidClose.next();
		}
	}
}
