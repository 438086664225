import { UserType } from './../../auth/user-type.enum';
import { Component, OnInit, ViewChild } from '@angular/core';
import version from '../../../environments/version.json';
import { IonMenu, Platform } from '@ionic/angular';
import { App } from '@capacitor/app';
import { UtilsService } from '../utils/utils.service';
import { AuthService } from '../../auth/auth.service';
import { AuthInfo } from '../../auth/auth-info.interface';

@Component({
	selector: 'app-mobile-menu',
	templateUrl: './mobile-menu.component.html',
	styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
	isBetaVersion = version.beta;
	versionInfo = version.versionNumber;
	appBuild = undefined;

	authInfo: AuthInfo;
	currentUserFirstName: string = '';
	currentUserLastName: string = '';
	currentUserRole: UserType;

	@ViewChild('mobileAppMenu') appMobileMenu: IonMenu;

	constructor(
		public authSrv: AuthService,
		private utils: UtilsService,
		private plt: Platform
	) {
		this.plt.ready().then((_) => {
			if (this.utils.isNative) {
				App.getInfo().then((info) => {
					this.appBuild = info.build;
				});
			}
		});
	}

	ngOnInit() {
		// subscribe to login status
		this.authSrv.loginStatusChanged$.subscribe(() => {
			this.authInfo = this.authSrv.authInfo;
			// check if there is a cognito user data
			if (this.authSrv.authInfo) {
				const userAttribues = this.authSrv.authInfo.UserAttributes;
				const firstName = userAttribues.find(
					(x) => x.Name === 'custom:firstName'
				).Value;
				const lastName = userAttribues.find(
					(x) => x.Name === 'custom:lastName'
				).Value;
				const role = userAttribues.find(
					(x) => x.Name === 'custom:role'
				).Value;

				// save to variables
				this.currentUserFirstName = firstName;
				this.currentUserLastName = lastName;
				this.currentUserRole = role as UserType;
			}
		});
	}

	onLogout() {
		this.authSrv.logoutUser();
	}

	onToggleMobileMenu() {
		this.appMobileMenu.toggle();
	}

	handleClose() {
		this.utils.triggerMobileMainMenuStatus('close');
	}

	handleOpen() {
		this.utils.triggerMobileMainMenuStatus('open');
	}
}
