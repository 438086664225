import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LoggerService {
  constructor() {}

  /**
   * Logs given message
   * @param message
   * @param vars
   */
  public log(message: any, ...vars: any) {
    if (vars.length === 0) {
      console.log(message);
    } else if (vars.length === 1) {
      console.log(message, vars[0]);
    } else {
      console.log(message, vars);
    }
  }

  /**
   * Error-Logs given message
   * @param message
   * @param vars
   */
  public error(message: any, ...vars: any) {
    if (vars.length === 0) {
      console.error(message);
    } else if (vars.length === 1) {
      console.error(message, vars[0]);
    } else {
      console.error(message, vars);
    }
  }

  /**
   * Warn-Logs given message
   * @param message
   * @param vars
   */
  public warn(message: any, ...vars: any) {
    if (vars.length === 0) {
      console.warn(message);
    } else if (vars.length === 1) {
      console.warn(message, vars[0]);
    } else {
      console.warn(message, vars);
    }
  }
}
